.content-dialog {
    position: relative;
    margin: auto;
    width: 500px;
    &.character{
        height: 475px;
    }
    &.normal {
        height: 575px;
    }
    & .content-card-contents {
        display: flex;
        height: 100%;
        & .content-dialog-contents {
            display:flex;
            flex-direction: column;
            width: 100%;
            & .content-title {
                margin: 20px 0 20px 0;
                font-size: 18px;
                font-weight: bold;
                text-align: center;
                text-transform: capitalize;
            }
            & .contents {
                height: 100%;
                width: 100%;
                margin: auto;
            }
        }
    }
}