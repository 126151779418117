.about-container {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-around;
    margin-bottom: 75px;
    position: relative;
    & .character-img {
        height: 500px;
        & img {
            height: 100%
        }
    }
    & .about-text {
        width: 400px;
        margin-top: auto;
        margin-bottom: auto;
    }
    .admin-button {
        position: absolute;
        bottom: -50px;
        left: 40px;
        width: 250px;
        display: flex;
        justify-content: space-between;
    }
    &.tablet {
        margin-bottom: 25px;
        & .character-img {
            height: 400px;
            margin: auto
        }
    }
    &.mobile {
        margin-bottom: 25px;
        & .character-img {
            height: 300px;
            margin: auto;
            display: none;
        }
        & .about-text {
            margin: 5px;
        }
    }
}