.header-bar {
    display: flex;
    flex-wrap: nowrap;
    height: 50px;
    align-items: center;
    overflow: hidden;
    justify-content: space-between;
    & .header-menu-button {
        margin-left: 35px;
        & button {
            color: #007bff;
            text-decoration: none;
            background-color: transparent;
            border: none;
        }
        & button:hover {
            color: #0056b3;
            text-decoration: underline;
        }
        & button:focus {
            border: none;
            outline: none;
        }
    }
    & .links-container {
        width: 100vw;
        display: flex;
        margin: 0px 20px 0px 20px;
        & .home-container {
            margin: 0px 10px 0px 10px;
            width: 75px;
        }
        & .character-container {
            margin: 0px 10px 0px 10px;
            width: 125px;
            & button {
                color: #007bff;
                text-decoration: none;
                background-color: transparent;
                border: none;
            }
            & button:hover {
                color: #0056b3;
                text-decoration: underline;
            }
            & button:focus {
                border: none;
                outline: none;
            }
        }
        & .universal-page-container {
            margin: 0px 10px 0px 10px;
            width: 125px;
        }
        & .learning-page-container {
            margin: 0px 10px 0px 10px;
            width: 125px;
        }
    }
    & .user-container {
        display: flex;
        justify-content: center;
        width: 300px;
        float: right;
        margin: 0px 10px 0px 10px;
        & button {
            color: #007bff;
            text-decoration: none;
            background-color: transparent;
            border: none;
            cursor: pointer;
        }
        & button:hover {
            color: #0056b3;
            text-decoration: underline;
        }
        & button:focus {
            border: none;
            outline: none;
        }
    }
}

.MuiList-root {
    display: flex;
    flex-wrap: wrap;
    & ul {
        padding: 8px 8px 8px 8px;
    }
    & li {
        height: 90px;
        width: 115px;
        padding: 0px 0px 0px 0px;

        & img {
            max-width:100%;
            max-height:100%;
        }
    }
}

.header-bar-menu {
    & .MuiPaper-root {
        width: 350px;
        & .MuiList-root {
            display: flex;
            flex-direction: column;
            padding: 20px;
            & li {
                height: auto;
                width: auto;
            }
        }
        & button {
            color: #007bff;
            text-decoration: none;
            background-color: transparent;
            border: none;
            padding: 0;
        }
        & button:hover {
            color: #0056b3;
            text-decoration: underline;
        }
        & button:focus {
            border: none;
            outline: none;
        }
        & .MuiPaper-rounded {
            border-radius: 0px;
        }
        .MuiAccordion-root {
            margin: 0px;
            box-shadow: none;
            &.Mui-expanded {
                margin: 0px;
            }
            & .MuiAccordionSummary-root {
                padding: 0px;
                min-height: 0px;
                color: #007bff;
                & .MuiAccordionSummary-content {
                    margin: 0px;
                }
            }
            & .MuiAccordionDetails-root {
                display: contents;
                & .character-list {
                    display: flex;
                    flex-wrap: wrap;
                    & .icon-select {
                        width: 75px;
                        padding: 0px;
                        margin: 1px -4px 1px -4px;
                    }
                }
            }
        }
    }
}