.kame-container {
    margin: 75px 150px 75px 150px;
    & .kame-row {
        display: flex;
        & .kame-details {
            max-width: 60%;
            margin: auto 10px auto 10px;
        }
    }
    & .kame-page-details {
        margin: 15px 0 15px 0;
    }
    & .image-fluid {
        width: 100%;
    }
    &.small_desktop {
        & .basics-container {
            & .basics-card {
                & .basics-card-title-container {
                    display: flex;
                    & .basics-card-title {
                        margin: 0px auto 0px auto;
                        font-size: 26px;
                        text-decoration: underline;
                    }
                }
                & .basics-card-details-container {
                    display: flex;
                    margin: 15px;
                    &.flip-true {
                        flex-direction: row-reverse;
                    }
                    & .basics-card-embed {
                        margin: auto 10px auto 10px;
                        & img {
                            max-width: 325px;
                        }
                    }
                    & .basics-card-details {
                        margin: auto;
                        max-width: 50%;
                        & p {
                            font-size: 14px;
                        }
                        & ul {
                            font-size: 14px;
                        }
                    }
                    
                }
            }
            
        }
        & .defense-container {
            & .defense-card {
                & .defense-card-title-container {
                    display: flex;
                    & .defense-card-title {
                        margin: 0px auto 0px auto;
                        font-size: 26px;
                        text-decoration: underline;
                    }
                }
                & .defense-card-details-container {
                    display: flex;
                    margin: 15px;
                    &.flip-true {
                        flex-direction: row-reverse;
                    }
                    & .defense-card-embed {
                        margin: auto 10px auto 10px;
                        & img {
                            max-width: 325px;
                        }
                    }
                    & .defense-card-details {
                        margin: auto;
                        max-width: 50%;
                        & p {
                            font-size: 14px;
                        }
                        & ul {
                            font-size: 14px;
                        }
                    }
                    
                }
            }
            
        }
        & .neutral-container {
            & .neutral-card {
                & .neutral-card-title-container {
                    display: flex;
                    & .neutral-card-title {
                        margin: 0px auto 0px auto;
                        font-size: 26px;
                        text-decoration: underline;
                    }
                }
                & .neutral-card-details-container {
                    display: flex;
                    margin: 15px;
                    &.flip-true {
                        flex-direction: row-reverse;
                    }
                    & .neutral-card-embed {
                        margin: auto 10px auto 10px;
                        & img {
                            max-width: 325px;
                        }
                    }
                    & .neutral-card-details {
                        margin: auto;
                        max-width: 50%;
                        & p {
                            font-size: 14px;
                        }
                        & ul {
                            font-size: 14px;
                        }
                    }
                    
                }
            }
            
        }
        & .offense-container {
            & .offense-card {
                & .offense-card-title-container {
                    display: flex;
                    & .offense-card-title {
                        margin: 0px auto 0px auto;
                        font-size: 26px;
                        text-decoration: underline;
                    }
                }
                & .offense-card-details-container {
                    display: flex;
                    margin: 15px;
                    &.flip-true {
                        flex-direction: row-reverse;
                    }
                    & .offense-card-embed {
                        margin: auto 10px auto 10px;
                        & img {
                            max-width: 325px;
                        }
                    }
                    & .offense-card-details {
                        margin: auto;
                        max-width: 50%;
                        & p {
                            font-size: 14px;
                        }
                        & ul {
                            font-size: 14px;
                        }
                    }
                    
                }
            }
            
        }
    } 
    &.tablet {
        margin: 75px;
        & .kame-row {
            flex-direction: column-reverse;
            & .kame-details {
                margin: auto;
            }
            & .kame-image {
                max-width: 325px;
                margin: auto;
            }
        }
        & .basics-container {
            & .basics-card {
                & .basics-card-title-container {
                    display: flex;
                    & .basics-card-title {
                        margin: 0px auto 0px auto;
                        font-size: 26px;
                        text-decoration: underline;
                    }
                }
                & .basics-card-details-container {
                    display: flex;
                    flex-direction: column;
                    &.flip-true {
                        flex-direction: column;
                    }
                    margin: 15px;
                    
                    & .basics-card-embed {
                        margin: auto;
                        margin-bottom: 15px;
                        & img {
                            max-width: 400px;
                        }
                    }
                    & .basics-card-details {
                        margin: auto;
                        max-width: 75%;
                        & p {
                            font-size: 14px;
                        }
                        & ul {
                            font-size: 14px;
                        }
                    }
                    
                }
            }
            
        }
        & .defense-container {
            & .defense-card {
                & .defense-card-title-container {
                    display: flex;
                    & .defense-card-title {
                        margin: 0px auto 0px auto;
                        font-size: 26px;
                        text-decoration: underline;
                    }
                }
                & .defense-card-details-container {
                    display: flex;
                    flex-direction: column;
                    margin: 15px;
                    &.flip-true {
                        flex-direction: column;
                    }
                    
                    & .defense-card-embed {
                        margin: auto;
                        margin-bottom: 15px;
                        & img {
                            max-width: 400px;
                        }
                    }
                    & .defense-card-details {
                        margin: auto;
                        max-width: 90%;
                        & p {
                            font-size: 14px;
                        }
                        & ul {
                            font-size: 14px;
                        }
                    }
                    
                }
            }
            
        }
        & .neutral-container {
            & .neutral-card {
                & .neutral-card-title-container {
                    display: flex;
                    & .neutral-card-title {
                        margin: 0px auto 0px auto;
                        font-size: 26px;
                        text-decoration: underline;
                    }
                }
                & .neutral-card-details-container {
                    display: flex;
                    flex-direction: column;
                    margin: 15px;
                    &.flip-true {
                        flex-direction: column;
                    }
                    
                    & .neutral-card-embed {
                        margin: auto;
                        margin-bottom: 15px;
                        & img {
                        max-width: 400px;
                        }
                    }
                    & .neutral-card-details {
                        margin: auto;
                            max-width: 90%;
                        & p {
                            font-size: 14px;
                        }
                        & ul {
                            font-size: 14px;
                        }
                    }
                    
                }
            }
            
        }
        & .offense-container {
            & .offense-card {
                & .offense-card-title-container {
                    display: flex;
                    & .offense-card-title {
                        margin: 0px auto 0px auto;
                        font-size: 26px;
                        text-decoration: underline;
                    }
                }
                & .offense-card-details-container {
                    display: flex;
                    flex-direction: column;
                    margin: 15px;
                    &.flip-true {
                        flex-direction: column;
                    }
                    
                    & .offense-card-embed {
                        margin: auto;
                        margin-bottom: 15px;
                        & img {
                            max-width: 400px;
                        }
                    }
                    & .offense-card-details {
                        margin: auto;
                        max-width: 90%;
                        & p {
                            font-size: 14px;
                        }
                        & ul {
                            font-size: 14px;
                        }
                    }
                    
                }
            }
            
        }
    }
    &.mobile {
        margin: 25px;
        & .kame-row {
            flex-direction: column-reverse;
            & .kame-details {
                margin:  0px 10px 0px 10px;
                max-width: 100%;
            }
            & .kame-image {
                max-width: 325px;
                margin: auto;
            }
        }
        & .learning-tab-box {
            padding: 0px;
        }
        & .basics-container {
            & .basics-card {
                & .basics-card-container {
                    padding: 8px;
                    & .basics-card-title-container {
                        display: flex;
                        & .basics-card-title {
                            margin: 0px auto 0px auto;
                            font-size: 26px;
                            text-decoration: underline;
                        }
                    }
                    & .basics-card-details-container {
                        display: flex;
                        margin: 10px;
                        flex-direction: column;
                        &.flip-true {
                            flex-direction: column;
                        }
                        & .basics-card-embed {
                            margin: auto 10px auto 10px;
                            & img {
                                max-width: 100%;
                            }
                        }
                        & .basics-card-details {
                            margin: auto;
                            max-width: 90%;
                            & p {
                                font-size: 14px;
                            }
                            & ul {
                                font-size: 14px;
                            }
                        }
                        
                    }
                }
            }
            
        }
        & .defense-container {
            & .defense-card {
                & .defense-card-container {
                    padding: 8px;
                    & .defense-card-title-container {
                        display: flex;
                        & .defense-card-title {
                            margin: 0px auto 0px auto;
                            font-size: 26px;
                            text-decoration: underline;
                        }
                    }
                    & .defense-card-details-container {
                        display: flex;
                        margin: 10px;
                        flex-direction: column;
                        &.flip-true {
                            flex-direction: column;
                        }
                        & .defense-card-embed {
                            margin: auto 10px auto 10px;
                            & img {
                                max-width: 100%;
                            }
                        }
                        & .defense-card-details {
                            margin: auto;
                            max-width: 90%;
                            & p {
                                font-size: 14px;
                            }
                            & ul {
                                font-size: 14px;
                            }
                        }
                        
                    }
                }
            }
            
        }
        & .neutral-container {
            & .neutral-card {
                & .neutral-card-container {
                    padding: 8px;
                    & .neutral-card-title-container {
                        display: flex;
                        & .neutral-card-title {
                            margin: 0px auto 0px auto;
                            font-size: 26px;
                            text-decoration: underline;
                        }
                    }
                    & .neutral-card-details-container {
                        display: flex;
                        margin: 10px;
                        flex-direction: column;
                        &.flip-true {
                            flex-direction: column;
                        }
                        & .neutral-card-embed {
                            margin: auto 10px auto 10px;
                            & img {
                                max-width: 100%;
                            }
                        }
                        & .neutral-card-details {
                            margin: auto;
                            max-width: 90%;
                            & p {
                                font-size: 14px;
                            }
                            & ul {
                                font-size: 14px;
                            }
                        }
                        
                    }
                }
            }
        }
        & .offense-container {
            & .offense-card {
                & .offense-card-container {
                    padding: 8px;
                    & .offense-card-title-container {
                        display: flex;
                        & .offense-card-title {
                            margin: 0px auto 0px auto;
                            font-size: 26px;
                            text-decoration: underline;
                        }
                    }
                    & .offense-card-details-container {
                        display: flex;
                        margin: 10px;
                        flex-direction: column;
                        &.flip-true {
                            flex-direction: column;
                        }
                        & .offense-card-embed {
                            margin: auto 10px auto 10px;
                            & img {
                                max-width: 100%;
                            }
                        }
                        & .offense-card-details {
                            margin: auto;
                            max-width: 90%;
                            & p {
                                font-size: 14px;
                            }
                            & ul {
                                font-size: 14px;
                            }
                        }
                        
                    }
                }
            }
        }
    }
    
    & .basics-container {
        & .basics-card {
            margin: 5px;
            & .basics-card-title-container {
                display: flex;
                & .basics-card-title {
                    margin: 0px auto 0px auto;
                    font-size: 26px;
                    text-decoration: underline;
                }
            }
            & .basics-card-details-container {
                display: flex;
                margin: 15px;
                &.flip-true {
                    flex-direction: row-reverse;
                }
                & .basics-card-embed {
                    margin: auto 10px auto 10px;
                    & img {
                        max-width: 400px;
                    }
                }
                & .basics-card-details {
                    margin: auto;
                    max-width: 50%;
                    & p {
                        font-size: 14px;
                    }
                    & ul {
                        font-size: 14px;
                    }
                }
                
            }
        }
        
    }
    & .defense-container {
        & .defense-card {
            margin: 5px;
            & .defense-card-title-container {
                display: flex;
                & .defense-card-title {
                    margin: 0px auto 0px auto;
                    font-size: 26px;
                    text-decoration: underline;
                }
            }
            & .defense-card-details-container {
                display: flex;
                margin: 15px;
                &.flip-true {
                    flex-direction: row-reverse;
                }
                & .defense-card-embed {
                    margin: auto 10px auto 10px;
                    & img {
                        max-width: 400px;
                    }
                }
                & .defense-card-details {
                    margin: auto;
                    max-width: 50%;
                    & p {
                        font-size: 14px;
                    }
                    & ul {
                        font-size: 14px;
                    }
                }
                
            }
        }
        
    }
    & .neutral-container {
        & .neutral-card {
            margin: 5px;
            & .neutral-card-title-container {
                display: flex;
                & .neutral-card-title {
                    margin: 0px auto 0px auto;
                    font-size: 26px;
                    text-decoration: underline;
                }
            }
            & .neutral-card-details-container {
                display: flex;
                margin: 15px;
                &.flip-true {
                    flex-direction: row-reverse;
                }
                & .neutral-card-embed {
                    margin: auto 10px auto 10px;
                    & img {
                        max-width: 400px;
                    }
                }
                & .neutral-card-details {
                    margin: auto;
                    max-width: 50%;
                    & p {
                        font-size: 14px;
                    }
                    & ul {
                        font-size: 14px;
                    }
                }
                
            }
        }
        
    }
    & .offense-container {
        & .offense-card {
            margin: 5px;
            & .offense-card-title-container {
                display: flex;
                & .offense-card-title {
                    margin: 0px auto 0px auto;
                    font-size: 26px;
                    text-decoration: underline;
                }
            }
            & .offense-card-details-container {
                display: flex;
                margin: 15px;
                &.flip-true {
                    flex-direction: row-reverse;
                }
                & .offense-card-embed {
                    margin: auto 10px auto 10px;
                    & img {
                        max-width: 400px;
                    }
                }
                & .offense-card-details {
                    margin: auto;
                    max-width: 50%;
                    & p {
                        font-size: 14px;
                    }
                    & ul {
                        font-size: 14px;
                    }
                }
                
            }
        }
        
    }
}

.embed-responsive-item {
    max-width: 100%;
}