.message-container {
    position: fixed;
    top: 90%;
    left: 0;
    right: 0;
    margin: 0 auto;
    width: 66%;
    height: 50px;
    border: solid 1px;
    border-radius: 8px;
    color: white;
    display: flex;
    justify-content: center;
    z-index: 10;
    &.error {
        background-color: rgb(255, 102, 0);
        padding: 10px;
        border: rgb(255, 102, 0);
        border-radius: 8px;
        color: white;
    }
    &.success {
        background-color: rgb(0, 128, 21);
        padding: 10px;
        border:rgb(0, 128, 21);
        border-radius: 8px;
        color: white;
    }
}

