.app-container {
    display:flex;
    flex-direction: column;
    height: 100vh;
    & .app-content-container {   
        flex: 1 0 auto;
        background-color: #e2e5e5;
    }
    & .footer {
        flex-shrink: 0;
    }   
}