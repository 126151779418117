.supers-container {
    margin-top: 75px;
    margin-bottom: 75px;
    
    & .supers-header {
        justify-content: center;
        display: flex;
        margin-bottom: 25px;
    }

    & .admin-button {
        position: relative;
        left: 50px;
    }

    & .super-card {
        width: 90%;
        margin: 15px auto 0px auto;
        align-items: center;
        & .super-details {
            display: flex;
            flex-direction: column;
            & .super-content {
                display: flex;
                align-items: center;
                padding: 24px 24px 24px 24px;
                & .super-image {
                    margin: auto;
                    & img{
                        max-height: 300px;
                        vertical-align: middle;
                        max-width: 100%;
                    }
                }
                
                & .super-info {
                    width: 100%;
                    margin: auto 0px auto 0px;
                    display: flex;
                    flex-direction: column;
                    & .admin-button {
                        position: relative;
                        display: flex;
                        justify-content: space-evenly;
                        height: 35px;
                        max-width: 100%;
                        & button {
                            max-height: 35px;
                        }
                    }
                    & .MuiTableContainer-root {
                        overflow-wrap: break-word;
                        & .MuiTableRow-root {
                            & .notes-row {
                                & td {
                                    height: 65px;
                                }
                            }
                        }
                    }  
                }
            }
        }
        & .super-variant-content {
            display:flex;
            & .MuiAccordion-root {
                width: 100%;
                & .MuiAccordionSummary-content {
                    flex-grow: 0;
                    color: #007bff;
                }
                & .super-variants-container {
                    margin: 10px auto 10px auto;
                    min-width: 90%;
                    & .super-variant-card {
                        width: 100%;
                        margin: 0px auto 0px auto;
                        align-items: center;
                        & .super-variant-content {
                            display: flex;
                            flex-direction: column;
                            & .admin-button {
                                position: relative;
                                left: 0px;
                                display: flex;
                                justify-content: space-evenly;
                            }
                            & .super-variant-image {
                                padding: 24px 24px 24px 24px;
                                max-width: 340px;
                                margin: auto;
                                display: flex;
                                min-width: 300px;
                                & img{
                                    max-height: 300px;
                                    vertical-align: middle;
                                    max-width: 100%;
                                    margin: 0 auto 0 auto
                                }
                            }
                            & .variant-graph {
                                margin: 15px auto 15px auto;
                                display: flex;
                                justify-content: center;
                            }
                        }
                    }
                }
            }
        }
        
        & .super-graph {
            margin: 15px auto 15px auto;
        }
    }   

    &.tablet {
        & .super-content {
            flex-direction: column;
        }
    }
    &.mobile {
        & .super-content {
            flex-direction: column;
            padding: 0px !important;
        }
        & .super-variant-content {
            & .super-variant-image {
                padding: 12px !important;
                max-width: none !important;
                min-width: 0px !important;
            }
        }
    }
}