.assists-container {
    margin-top: 50px;
    margin-bottom: 50px;

    & .assists-header {
        justify-content: center;
        display: flex;
        margin-bottom: 25px;
    }

    & .assist-card {
        width: 90%;
        margin: 15px auto 0px auto;
        align-items: center;
        & .assist-details{
            display: flex;
            flex-direction: column;
            & .assist-content {
                display: flex;
                align-items: center;
                padding: 24px 24px 24px 24px;
                & .assist-image {
                    display: flex;
                    & img{
                        max-height: 300px;
                        vertical-align: middle;
                        max-width: 100%;
                        margin: 0 auto 0 auto
                    }
                }
                
                & .assist-data {
                    width: 100%;
                    margin: auto 0px auto 0px;
                    display: flex;
                    flex-direction: column;
                    & .admin-button {
                        position: relative;
                        left: 0px;
                        display: flex;
                        justify-content: space-evenly;
                    }
                    & .MuiTableContainer-root {
                        overflow-wrap: break-word;
                        & .notes-row {
                            & td {
                                height: 65px;
                            }
                        }
                    }
                }
            }
            & .assist-graph {
                margin: 15px auto 15px auto;
            }
        }  
    }    

    &.tablet {
        & .assist-content {
            flex-direction: column;
        }
    }
    &.mobile {
        & .assist-content {
            flex-direction: column;
        }
    }
}