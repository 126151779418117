.user-dialog-container {
    position: fixed;
    top: 50%;
    left: 50%;
    -webkit-transform: translateX(-50%) translateY(-50%);
    -moz-transform: translateX(-50%) translateY(-50%);
    -ms-transform: translateX(-50%) translateY(-50%);
    transform: translateX(-50%) translateY(-50%);
    height: 100vh;
    width: 100vw;
    background-color: rgba(255, 166, 0, 0.658);
    display: flex;
    z-index: 1;
}

.content-dialog-container {
    position: fixed;
    top: 50%;
    left: 50%;
    -webkit-transform: translateX(-50%) translateY(-50%);
    -moz-transform: translateX(-50%) translateY(-50%);
    -ms-transform: translateX(-50%) translateY(-50%);
    transform: translateX(-50%) translateY(-50%);
    height: 100vh;
    width: 100vw;
    background-color: rgba(0, 81, 255, 0.795);
    display: flex;
    z-index: 1;
}