.specials-container {
    margin-top: 75px;
    margin-bottom: 75px;
    
    & .specials-header {
        justify-content: center;
        display: flex;
        margin-bottom: 25px;
    }

    & .admin-button {
        position: relative;
        left: 50px;
    }

    & .accordion .admin-button {
        position: relative;
        left: 0px;
        margin: 0px 10px 0px 10px;
    }

    & .special-card {
        width: 90%;
        margin: 15px auto 0px auto;
        align-items: center;
        & .special-content {
            display: flex;
            align-items: center;
            padding: 24px 24px 24px 24px;
            & .special-image {
                width: 615px;
                display: flex;
                min-width: 300px;
                & img{
                    max-height: 300px;
                    vertical-align: middle;
                    max-width: 100%;
                    margin: 0 auto 0 auto
                }
            }
            
            & .special-info {
                width: 100%;
                margin: auto 0px auto 0px;
                display: flex;
                flex-direction: column;
                & .admin-button {
                    position: relative;
                    display: flex;
                    justify-content: space-evenly;
                    height: 35px;
                    max-width: 100%;
                    & button {
                        max-height: 35px;
                    }
                }
                & .MuiTableContainer-root {
                    overflow-wrap: break-word;
                    & .MuiTableRow-root {
                        & .notes-row {
                            & td {
                                height: 65px;
                            }
                        }
                    }
                }  
            }
            & .MuiAccordion-root {
                width: 100%;
                & .MuiAccordionSummary-content {
                    flex-grow: 0;
                    color: #007bff;
                }
                & .special-variants-container {
                    margin: 10px auto 10px auto;
                    min-width: 90%;
                    & .special-variant-card {
                        width: 100%;
                        margin: 0px auto 0px auto;
                        align-items: center;
                        & .special-variant-content {
                            display: flex;
                            flex-direction: column;
                            & .admin-button {
                                position: relative;
                                left: 0px;
                                display: flex;
                                justify-content: space-evenly;
                            }
                            & .special-variant-image {
                                padding: 24px 24px 24px 24px;
                                max-width: 400px;
                                min-width: 300px;
                                margin: auto;
                                display: flex;
                                & img{
                                    max-height: 300px;
                                    vertical-align: middle;
                                    max-width: 100%;
                                    margin: 0 auto 0 auto
                                }
                            }
                            & .variant-graph {
                                margin: 15px auto 15px auto;
                                display: flex;
                                justify-content: center;
                            }  
                        }
                    }
                }
            }
            
        }
        & .special-graph {
            margin: 15px auto 15px auto;
        }
    }

    &.tablet {
        margin-top: 25px;
        margin-bottom: 25px;
        & .special-content{
            flex-direction: column;
            padding: 0px;
            & .special-data-table-one {
                & td {
                    padding-left: 50px;
                }
            }
        }
    }

    &.mobile {
        margin-top: 25px;
        margin-bottom: 25px;
        & .special-content{
            flex-direction: column;
            padding: 0px;
            & .special-data-table-one {
                & td {
                    padding-left: 50px;
                }
            }
        }
    }
        
}